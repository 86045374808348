import {initializeApp} from 'firebase/app'
import {getAuth} from 'firebase/auth'
import {getDatabase} from 'firebase/database'

const firebaseConfig = {
  apiKey: 'AIzaSyAw9QfOjunh5JNDmU68TcfL6EEESHMBXGs',
  authDomain: 'fdt-team.firebaseapp.com',
  projectId: 'fdt-team',
  storageBucket: 'fdt-team.appspot.com',
  messagingSenderId: '1018395035224',
  appId: '1:1018395035224:web:7b2fdc0574e3e012686c2d',
  databaseURL: 'https://fdt-team-default-rtdb.europe-west1.firebasedatabase.app'
}

// Инициализация FireBase
initializeApp(firebaseConfig)

// Инициализация авторизации
const fbAuth = getAuth()

// Инициализация базы данных
const fbDB = getDatabase()

export {fbAuth, fbDB}
