<template>
  <div id="slide-out" class="sidenav" ref="sidebar">
    <div class="user_info">
      <span class="ui_user">
        <!-- <i class="material-icons">person</i> -->
        Ученик
      </span>
      <span class="ui_mail">
        <!-- <i class="material-icons">mail</i> -->
        {{ $store.getters.user ? $store.getters.user.email : 'email@google.com' }}
      </span>
    </div>
    <div class="nav">
      <router-link to="/profile" class="waves-effect waves-teal"><i class="material-icons">badge</i>Профиль</router-link>
      <router-link to="/course" class="waves-effect waves-teal"><i class="material-icons">school</i>Курс</router-link>
      <router-link to="/bookmarks" class="waves-effect waves-teal"><i class="material-icons">bookmark</i>Закладки</router-link>
      <!-- <a href="#" class="waves-effect waves-teal"><i class="material-icons">calculate</i>Калькулятор</a> -->
    </div>
    <div class="exit">
      <a href="" class="waves-effect waves-red" @click.prevent="$store.dispatch('signOut')"><i class="material-icons">logout</i>Выйти</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sidenav: null
    }
  },
  mounted() {
    this.sidenav = M.Sidenav.init(this.$refs.sidebar)
  },
  methods: {
    sidenavClose() {
      // console.log('sidenavClose')
      if (this.sidenav && this.sidenav.close) this.sidenav.close()
    }
  },
  unmounted() {
    this.sidenavClose()
  },
  watch: {
    '$route.name'() {
      this.sidenavClose()
    }
  }
}
</script>

<style scoped>
@import url('@/assets/header-navbar.css');
</style>
