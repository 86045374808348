<template>
  <div class="progress">
    <div class="indeterminate"></div>
  </div>
</template>

<style scoped>
.progress {
  position: fixed;
  height: 2px;
  margin: 0;
  left: 0;
  top: 50px;
  right: 0;
  background: rgba(255, 255, 255, 0.3);
  z-index: 10;
}
.progress .indeterminate {
  background: #42b883;
}
</style>
