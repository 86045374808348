import {fbDB} from '@/firebase/config'
import {onValue, push, ref, update, get} from 'firebase/database'
import Toast from '@/utils/toast'
import router from '@/router/router'

const logger = false

export default {
  state: {
    writeAccess: null,
    menuLoad: false,
    menu: {},
    pagesLoad: false,
    pages: {},
    pageLoad: false,
    adminForm: false,
    courseAccess: null
  },
  actions: {
    getAccess({commit}) {
      update(ref(fbDB, 'course/permision'), {pole: 1})
        .then(() => {
          commit('setAccess', true)
          if (logger) console.log('ЕСТЬ права на запись данных в базу', 'Вот это нихуя себе')
        })
        .catch(error => {
          // Включение защиты от копирования
          const style = document.createElement('style')
          style.innerHTML = `*, :before, :after {-webkit-user-select: none; -webkit-touch-callout: none; -moz-user-select: none; -ms-user-select: none;user-select: none;}`
          document.head.appendChild(style)

          document.onselectstart = () => false
          document.onCopy = () => false
          document.onCut = () => false
          document.onDrag = () => false
          document.onDrop = () => false
          document.oncontextmenu = () => false
          document.dblclick = () => false

          commit('setAccess', false)
          if (error.code === 'PERMISSION_DENIED') {
            if (logger) console.log('НЕТ прав на запись данных в базу')
          } else {
            if (logger) console.log('Ошибка проверки доступа на запись в базу данных', error)
          }
        })
    },
    getCourseAccess({commit}) {
      onValue(
        ref(fbDB, `course/permision`),
        snapshot => {
          // console.log('Доступ к курсу есть', snapshot.val())
          if (logger) console.log('getCourseAccess action', snapshot.val())
          commit('setCourseAccess', true)
        },
        error => {
          if (error.code === 'PERMISSION_DENIED') {
            commit('setCourseAccess', false)
          }
          if (logger) console.log('getPage action', error)
        },
        {onlyOnce: true}
      )
    },
    getMenu({commit}) {
      onValue(
        ref(fbDB, 'menu'),
        snapshot => {
          if (logger) console.log('Меню из базы загружено', snapshot.val())
          commit('setMenu', snapshot.val() || {})
        },
        {onlyOnce: true}
      )
    },
    getPage({state, commit}, {id_chapter, id_subchapter}) {
      if (logger) console.log('getPage action start', id_subchapter)
      // return
      state.pageLoad = false

      onValue(
        ref(fbDB, `course/chapters/${id_chapter}/subchapters/${id_subchapter}`),
        snapshot => {
          const res = snapshot.val() || {title: '', description: '', html: ''}
          if (logger) console.log('getPage action', res)
          commit('setSubChapter', {id_subchapter, id_chapter, title: res.title, description: res.description, html: res.html})
          state.pageLoad = true
        },
        error => {
          if (error.code === 'PERMISSION_DENIED') {
            commit('setSubChapter', {id_subchapter, id_chapter, title: 'PERMISSION_DENIED', description: '', html: ''})
            // Toast.err('Нет прав для чтения страницы!')
          }
          if (logger) console.log('getPage action', error)
          state.pageLoad = true
        },
        {onlyOnce: true}
      )
    },
    getPages({commit}) {
      onValue(
        ref(fbDB, 'course'),
        snapshot => {
          if (logger) console.log('Все страницы из базы загружены', snapshot.val())
          commit('setChapters', snapshot.val() || {})
        },
        {onlyOnce: true}
      )
    },
    getChapter({state, commit}, {id_chapter}) {
      state.pageLoad = false
      onValue(
        ref(fbDB, `course/chapters/${id_chapter}/title`),
        snapshot => {
          const res = {title: snapshot.val() || ''}
          if (logger) console.log('getChapter action', res)
          commit('setChapter', {id_chapter, title: res.title})
          state.pageLoad = true
        },
        {onlyOnce: true}
      )
    },
    newChapter({commit}, title) {
      if (title) {
        push(ref(fbDB, `course/chapters`), {title})
          .then(res => {
            if (res.key) {
              // Создается пункт в меню
              update(ref(fbDB, `menu/${res.key}`), {title})

              commit('setChapter', {id_chapter: res.key, title})

              if (logger) console.log(`Новый раздел "${title}" создан, ему присвоен id: ${res.key}`, res)
              Toast.mes(`Раздел "<strong>${title}</strong>" создан`, false)
            } else {
              if (logger) console.log(`Ошибка создания раздела "${title}" ему не присвоен id`, res)
              Toast.err(`Ошибка при создании раздела`)
            }
          })
          .catch(error => {
            if (logger) console.log(`Ошибка при создании раздела`, error)
            Toast.err(error.code ? error.code : error.message)
          })
      } else {
        Toast.err('Название раздела не должно быть пустым!')
      }
    },
    newSubChapter({commit}, {id_chapter, title}) {
      if (title) {
        push(ref(fbDB, `course/chapters/${id_chapter}/subchapters`), {title, description: '', html: ''})
          .then(res => {
            if (res.key) {
              // Создается пункт в меню
              update(ref(fbDB, `menu/${id_chapter}/subchapters`), {[res.key]: title})

              commit('setSubChapter', {id_subchapter: res.key, id_chapter, title, description: '', html: ''})
              if (logger) console.log(`Новый подраздел "${title}" создан, ему присвоен id: ${res.key}`, res)
              Toast.mes(`Подраздел "<strong>${title}</strong>" создан`, false)
            } else {
              if (logger) console.log(`Ошибка создания подраздела "${title}" ему не присвоен id`, res)
              Toast.err(`Ошибка при создании подраздела`)
            }
          })
          .catch(error => {
            if (logger) console.log(`Ошибка при создании подраздела`, error)
            Toast.err(error.code ? error.code : error.message)
          })
      } else {
        Toast.err('Название подраздела не должно быть пустым!')
      }
    },
    updateChapter({state, commit}, payload) {
      if (logger) console.log('State updateChapter', payload)
      state.adminForm = true

      const updates = {}
      updates[`course/chapters/${payload.id_chapter}${payload.remove ? '' : '/title'}`] = payload.remove ? null : payload.page_data.title
      updates[`menu/${payload.id_chapter}${payload.remove ? '' : '/title'}`] = payload.remove ? null : payload.page_data.title

      update(ref(fbDB), updates)
        // update(ref(fbDB), {[`course/chapters/${payload.id_chapter}${payload.remove ? '' : '/title'}`]: payload.remove ? null : payload.page_data.title})
        .then(res => {
          state.adminForm = false
          commit('updateChapter', payload)
          if (logger) console.log(`Раздел "${payload.page_data.title}" ${payload.remove ? 'удален' : 'обновлен'}`, res)
          Toast.mes(`Раздел "${payload.page_data.title}" ${payload.remove ? 'удален' : 'обновлен'}`)
        })
        .catch(error => {
          if (logger) console.log(`Ошибка при ${payload.remove ? 'удалении' : 'обновлении'} раздела`, error)
          Toast.err(error.code ? error.code : error.message)
        })
    },
    updateSubchapter({state, commit}, payload) {
      // if (logger) console.log('State updateSubchapter', payload)
      state.adminForm = true

      const updates = {}
      updates[`course/chapters/${payload.id_chapter}/subchapters/${payload.id_subchapter}`] = payload.remove ? null : payload.page_data
      updates[`menu/${payload.id_chapter}/subchapters/${payload.id_subchapter}`] = payload.remove ? null : payload.page_data.title

      update(ref(fbDB), updates)
        // update(ref(fbDB), {[`course/chapters/${payload.id_chapter}/subchapters/${payload.id_subchapter}`]: payload.remove ? null : payload.page_data})
        .then(res => {
          commit('updateSubchapter', payload)
          if (logger) console.log(`Подраздел "${payload.page_data.title}" ${payload.remove ? 'удален' : 'обновлен'}`, res)
          Toast.mes(`Подраздел "${payload.page_data.title}" ${payload.remove ? 'удален' : 'обновлен'}`)
        })
        .catch(error => {
          if (logger) console.log(`Ошибка при ${payload.remove ? 'удалении' : 'обновлении'} подраздела`, error)
          Toast.err(error.code ? error.code : error.message)
        })
        .finally(() => {
          state.adminForm = false
        })
    }
  },
  mutations: {
    setAccess: (state, flag) => {
      state.writeAccess = flag
    },
    setCourseAccess: (state, flag) => {
      state.courseAccess = flag
    },
    setMenu: (state, payload) => {
      state.menu = payload
      state.menuLoad = true
    },
    setChapter(state, {id_chapter, title}) {
      if (!state.pages.chapters) {
        state.pages.chapters = {}
      }
      state.pages.chapters[id_chapter] = {title}
      state.menu[id_chapter] = {title}
    },
    setChapters: (state, payload) => {
      state.pages = payload
      state.pagesLoad = true
    },
    setSubChapter(state, {id_chapter, id_subchapter, title, description, html}) {
      // Массив страниц
      if (!state.pages.chapters) {
        state.pages = {chapters: {}}
      }
      if (!state.pages.chapters[id_chapter]) {
        state.pages.chapters[id_chapter] = {subchapters: {}}
      }
      if (!state.pages.chapters[id_chapter].subchapters) {
        state.pages.chapters[id_chapter].subchapters = {}
      }
      state.pages.chapters[id_chapter].subchapters[id_subchapter] = {title, description, html}
      // Массив меню
      if (!state.menu[id_chapter]) {
        state.menu[id_chapter] = {subchapters: {}}
      }
      if (!state.menu[id_chapter].subchapters) {
        state.menu[id_chapter].subchapters = {}
      }
      if (title !== 'PERMISSION_DENIED') state.menu[id_chapter].subchapters[id_subchapter] = title
    },
    updateChapter(state, payload) {
      if (payload.remove) {
        delete state.pages.chapters[payload.id_chapter]
        delete state.menu[payload.id_chapter]
        router.push('/course')
      } else {
        // if (!state.pages.chapters) {
        //   state.pages = {chapters: {[payload.id_chapter]: {title: ''}}}
        // }
        // state.pages.chapters[payload.id_chapter].title = payload.page_data.title
        // state.menu[payload.id_chapter].title = payload.page_data.title
      }
    },
    updateSubchapter(state, payload) {
      if (payload.remove) {
        delete state.pages.chapters[payload.id_chapter].subchapters[payload.id_subchapter]
        delete state.menu[payload.id_chapter].subchapters[payload.id_subchapter]
        router.push('/course')
      } else {
        state.pages.chapters[payload.id_chapter].subchapters[payload.id_subchapter] = payload.page_data
        state.menu[payload.id_chapter].subchapters[payload.id_subchapter] = payload.page_data.title
      }
    },
    setAdminForm(state, flag = true) {
      state.adminForm = flag
    }
  },
  getters: {
    writeAccess: state => state.writeAccess,
    courseAccess: state => state.courseAccess,
    menuLoad: state => state.menuLoad,
    pageLoad: state => state.pageLoad,
    pagesLoad: state => state.pagesLoad,
    getPage: state => payload => {
      if (payload.id_chapter && payload.id_subchapter && state.pages.chapters) {
        if (state.pages.chapters[payload.id_chapter]) {
          if (state.pages.chapters[payload.id_chapter].subchapters[payload.id_subchapter]) {
            state.pageLoad = true
            return state.pages.chapters[payload.id_chapter].subchapters[payload.id_subchapter]
          } else {
            return null
          }
        } else {
          return null
        }
      } else if (payload.id_chapter && state.pages.chapters) {
        if (state.pages.chapters[payload.id_chapter]) {
          state.pageLoad = true
          return state.pages.chapters[payload.id_chapter].title
        } else {
          return null
        }
      }
      return false
    },
    getPages: state => state.pages.chapters,
    getMenu: state => state.menu,
    adminForm: state => state.adminForm,
    pagesSearch: state => {
      // Выводит массив данных для поиска ['заголовок': {id_chapter, id_subchapter}] ... из массива menu
      const res = []
      if (state.menu) {
        Object.keys(state.menu).forEach(id_chapter => {
          if (state.menu[id_chapter].subchapters) {
            Object.keys(state.menu[id_chapter].subchapters).forEach(id_subchapter => {
              res[state.menu[id_chapter].subchapters[id_subchapter].toLowerCase()] = {id_chapter, id_subchapter}
            })
          }
        })
      }
      // if (state.pages.chapters) {
      //   Object.keys(state.pages.chapters).forEach(id => {
      //     // console.log('item', id, this.$store.getters.getPages[id].subchapters)
      //     Object.keys(state.pages.chapters[id].subchapters).forEach(id_page => {
      //       // console.log('item', this.$store.getters.getPages[id].subchapters[id_page].title)
      //       res[state.pages.chapters[id].subchapters[id_page].title.toLowerCase()] = {id_chapter: id, id_subchapter: id_page}
      //     })
      //   })
      // }
      return res
    }
  }
}
