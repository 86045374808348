<template>
  <div id="modal-search" class="modal modal-search" ref="modalSearch">
    <div class="search-input-block">
      <a href="#" class="material-icons search valign-wrapper" @click.prevent="searchRun">search</a>
      <!-- <a href="#" class="material-icons close valign-wrapper" @click.prevent="inputSearch = ''">close</a> -->
      <input type="text" class="search-input browser-default" required v-model="inputSearch" placeholder="поиск" @keypress.enter="searchRun" @keyup="searchRun" />
      <h3 v-if="inputSearch.length < 2">Недавний поиск</h3>
      <h3 v-else>Результаты поиска</h3>
    </div>
    <div class="modal-content">
      <div class="search-result" v-if="pages.length && ($store.getters.searchQuery || inputSearch.length > 1)">
        <template v-for="item in pages" :key="item.id_subchapter">
          <router-link :to="`/course/${item.id_chapter}/${item.id_subchapter}`" class="truncate"
            ><i class="material-icons material-icons-outlined mi_before">description</i>{{ item.title }}<i class="material-icons mi_after">shortcut</i></router-link
          >
        </template>
      </div>
      <div class="search-result" v-else-if="!$store.getters.searchQuery && inputSearch.length < 2">
        <div class="no_results">
          <h4>Нет недавних запросов</h4>
        </div>
      </div>
      <div class="search-result" v-else>
        <div class="no_results">
          <i class="material-icons">search_off</i>
          <h4>
            Нет результатов по запросу "<span>{{ inputSearch }}</span
            >"
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      pages: [],
      inputSearch: ''
    }
  },
  mounted() {
    this.modal = M.Modal.init(this.$refs.modalSearch, {opacity: 0.4, endingTop: '3%'})
    this.$store.dispatch('getSearchQuery', this.$store.getters.uid)
    this.searchRun()
  },
  unmounted() {
    if (this.modal && this.modal.destroy) this.modal.destroy()
  },
  methods: {
    searchRun() {
      if (this.inputSearch.length > 2 || this.$store.getters.searchQuery) {
        const pages = this.$store.getters.pagesSearch
        const result = []

        Object.keys(pages).forEach(item => {
          if (item.includes(this.inputSearch.toLowerCase() || this.$store.getters.searchQuery)) {
            result.push({title: item, id_chapter: pages[item].id_chapter, id_subchapter: pages[item].id_subchapter})
          }
        })

        if (result.length && this.inputSearch.length > 2) {
          this.$store.dispatch('setSearchQuery', {uid: this.$store.getters.uid, query: this.inputSearch.toLowerCase()})
        }

        this.pages = result
      }
    }
  },
  watch: {
    '$store.getters.searchQuery'(val) {
      this.searchRun()
    },
    '$store.getters.pagesSearch'(val) {
      this.searchRun()
    },
    '$route.path'() {
      if (this.modal && this.modal.close) this.modal.close()
    }
  }
}
</script>

<style scoped>
@import url('@/assets/header-search-modal.css');
</style>
