import {createRouter, createWebHistory} from 'vue-router'
import store from '@/store/store'
import {fbAuth} from '@/firebase/config'
import {onAuthStateChanged} from 'firebase/auth'

const logger = false

const routes = [
  // {
  //   path: '/',
  //   name: 'index',
  //   redirect: '/course'
  //   // ,
  //   // meta: {layout: 'main', auth: true},
  //   // component: () => import('../views/Index.vue')
  // },
  {
    path: '/',
    name: 'lending',
    meta: {layout: 'empty', auth: false},
    component: () => import('../views/Lending.vue')
  },
  {
    path: '/course',
    name: 'course',
    meta: {layout: 'main', auth: true},
    component: () => import('../views/Course.vue')
  },
  {
    path: '/course/:id_chapter',
    name: 'course_chapter',
    meta: {layout: 'main', auth: true},
    component: () => import('../views/Chapter.vue')
  },
  {
    path: '/course/:id_chapter/:id_subchapter',
    name: 'course_subchapter',
    meta: {layout: 'main', auth: true},
    component: () => import('../views/SubChapter.vue')
  },
  {
    path: '/bookmarks',
    name: 'bookmarks',
    meta: {layout: 'main', auth: true},
    component: () => import('../views/Bookmarks.vue')
  },
  {
    path: '/bookmarks/:id_chapter/:id_subchapter',
    name: 'bookmarks_subchapter',
    meta: {layout: 'main', auth: true},
    component: () => import('../views/SubChapter.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {layout: 'main', auth: true},
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/404',
    name: '404',
    meta: {layout: 'main', auth: true},
    component: () => import('../views/404.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {layout: 'empty', auth: false},
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: {layout: 'empty', auth: false},
    component: () => import('../views/Register.vue')
  },
  {
    path: '/forgot',
    name: 'forgot',
    meta: {layout: 'empty', auth: false},
    component: () => import('../views/Forgot.vue')
  }
  // ,{
  //   path: '*',
  //   name: '404',
  //   meta: {layout: 'main', auth: true},
  //   component: () => import('../views/404.vue')
  // }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  mode: 'history',
  routes
})

onAuthStateChanged(fbAuth, user => {
  if (user) {
    // Обновляем данные пользователя
    store.commit('setUser', user)
    // Если мы находимся на странице, где авторизация не требуется, тогда перекидываем на главную
    if (!router.currentRoute.value.meta.auth || router.currentRoute.value.name === 'lending') router.push('/course')
    if (logger) console.log('Store user', true, user)
  } else {
    store.commit('setUser', null)
    // Если мы находимся на странице, где авторизация ТРЕБУЕТСЯ, тогда перекидываем на страницу авторизации
    if (router.currentRoute.value.meta.auth) router.push('/login')
    // sUser.state.uid = sUser.state.user = null
    if (logger) console.log('Store user', false, user)
  }
})

export default router
