import {fbDB} from '@/firebase/config'
import {onValue, push, ref, update} from 'firebase/database'
import Toast from '@/utils/toast'
import router from '@/router/router'

const logger = false

export default {
  state: {
    bookmarksLoad: false,
    user_info: {},
    bookmarks: {},
    searchQuery: '',
    pay_link: ''
  },
  actions: {
    getBookmarks({commit}, uid) {
      onValue(
        ref(fbDB, `users/${uid}/bookmarks`),
        snapshot => {
          commit('setBookmarks', snapshot.val() || {})
          if (logger) console.log('getBookmarks', snapshot.val())
        },
        {onlyOnce: true}
      )
    },
    setBookmark({commit}, payload) {
      update(ref(fbDB, `users/${payload.uid}/bookmarks/${payload.id_subchapter}`), {id_chapter: payload.id_chapter})
        .then(res => {
          commit('setBookmark', payload)
          if (logger) console.log(`Страница "${payload.title}" добавлена в закладки`, res)
          Toast.mes(`Закладка создана`)
        })
        .catch(error => {
          if (logger) console.log(`Закладка не создана`, error)
          Toast.err(error.code ? error.code : error.message)
        })
    },
    removeBookmark({commit}, payload) {
      update(ref(fbDB), {[`users/${payload.uid}/bookmarks/${payload.id_subchapter}`]: null})
        .then(res => {
          commit('removeBookmark', payload)
          if (logger) console.log(`Страница "${payload.title}" удалена из закладки`, res)
          Toast.mes(`Закладка удалена`, false)
        })
        .catch(error => {
          if (logger) console.log(`Закладка не удалена`, error)
          Toast.err(error.code ? error.code : error.message)
        })
    },
    getSearchQuery({commit}, uid) {
      onValue(
        ref(fbDB, `users/${uid}/search`),
        snapshot => {
          commit('setSearchQuery', snapshot.val())
          if (logger) console.log('setSearchQuery', snapshot.val())
        },
        {onlyOnce: true}
      )
    },
    setSearchQuery({commit}, payload) {
      update(ref(fbDB, `users/${payload.uid}/search/`), {query: payload.query})
        .then(res => {
          commit('setSearchQuery', payload)
          if (logger) console.log(`Поисковой запрос "${payload.query}" записан в базу`, res)
        })
        .catch(error => {
          if (logger) console.log(`Ошибка записи поискового запроса в базу`, error)
          // Toast.err(error.code ? error.code : error.message)
        })
    },
    getPayLink({commit}, uid) {
      onValue(
        ref(fbDB, `users/${uid}/pay_link`),
        snapshot => {
          commit('setPayLink', snapshot.val())
          if (logger) console.log('setPayLink action', snapshot.val())
        },
        {onlyOnce: true}
      )
    },
    setPayLink({commit}, payload) {
      update(ref(fbDB, `users/${payload.uid}/pay_link/`), {url: payload.url, expired_at: payload.expired_at})
        .then(res => {
          commit('setPayLink', payload)
          if (logger) console.log(`Ссылка на оплату "${payload.url}" записана в базу`, res)
        })
        .catch(error => {
          if (logger) console.log(`Ошибка записи ссылки на оплату в базу`, error)
          // Toast.err(error.code ? error.code : error.message)
        })
    }
  },
  mutations: {
    setBookmarks(state, payload) {
      state.bookmarks = payload
      state.bookmarksLoad = true
    },
    setBookmark(state, payload) {
      state.bookmarks[payload.id_subchapter] = {id_chapter: payload.id_chapter}
    },
    removeBookmark(state, payload) {
      delete state.bookmarks[payload.id_subchapter]
      if (router.currentRoute.value.name === 'bookmarks_subchapter') router.push('/bookmarks')
    },
    setSearchQuery(state, payload) {
      state.searchQuery = payload || {}
    },
    setPayLink(state, payload) {
      state.pay_link = payload || {}
    }
  },
  getters: {
    bookmarksLoad: state => state.bookmarksLoad,
    bookmarks: state => state.bookmarks,
    searchQuery: state => state.searchQuery.query,
    payLink: state => state.payLink
  }
}
