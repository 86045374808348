import {createApp} from 'vue'
import App from '@/App.vue'
import router from '@/router/router'
import store from '@/store/store'
import Loader from '@/components/Loader'
import Preloader from '@/components/Preloader'

const apph = createApp(App)
apph.config.globalProperties.logger = false
apph.use(store).use(router).component('Loader', Loader).component('Preloader', Preloader).mount('#app')
