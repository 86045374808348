<template>
  <header class="header z-depth-3">
    <div><router-link to="/course" class="logo">FDT Team</router-link></div>
    <div class="sidebar_btn">
      <!-- <a href="#" class="material-icons sidenav-trigger" @click.prevent="sibarMobile">more_vert</a> -->
      <a href="#" class="material-icons sidenav-trigger" data-target="sidebar_mobile">more_vert</a>
    </div>
    <div class="menu_btn">
      <a href="#" data-target="slide-out" class="sidenav-trigger show-on-large"><i class="material-icons">menu</i>Меню</a>
    </div>
    <div class="right-block">
      <div class="theme-toggle material-icons" @click="themeToggle"></div>
      <div class="search-block">
        <a href="#modal-search" class="modal-trigger"><i class="material-icons">search</i> поиск</a>
      </div>
    </div>
  </header>

  <HeaderSearchModal />
  <HeaderNavbar />
</template>

<script>
import HeaderNavbar from './app/HeaderNavbar.vue'
import HeaderSearchModal from './app/HeaderSearchModal.vue'

export default {
  methods: {
    sibarMobile() {
      document.querySelector('.sidebar').classList.toggle('active')
    },
    themeToggle() {
      localStorage.setItem('theme', document.querySelector('body').classList.toggle('dark'))
    }
  },
  components: {HeaderNavbar, HeaderSearchModal}
}
</script>

<style scoped>
@import url('@/assets/header.css');
</style>
