export default (message, users = 0) => {
  try {
    const BOT_API_KEY = '6402038283:AAHD2-mwRdkqq1J9BAolFnqrSsyEyGxpCy8'
    let MESSAGE_TEXT = '[FDT] ' + message

    fetch(`https://api.telegram.org/bot${BOT_API_KEY}/sendMessage?chat_id=845809846&text=${MESSAGE_TEXT}`) // Я
    if (!users) fetch(`https://api.telegram.org/bot${BOT_API_KEY}/sendMessage?chat_id=191301695&text=${MESSAGE_TEXT}`) // Коля
  } catch (e) {
    console.warn('Error telegram send:', e.message)
  }
}

//191301695

//https://api.telegram.org/bot6402038283:AAHD2-mwRdkqq1J9BAolFnqrSsyEyGxpCy8/getUpdates
