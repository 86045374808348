<template>
  <Header />

  <router-view />

  <Footer />
  <SocIcons />
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SocIcons from '@/components/app/SocIcons'

export default {
  beforeCreate() {
    // Проверка доступа для записи в базу (чтобы выводить админские штучки)
    this.$store.dispatch('getAccess')
    this.$store.dispatch('getMenu')
    this.$store.dispatch('getBookmarks', this.$store.getters.uid)
    this.$store.dispatch('getCourseAccess')
  },
  mounted() {
    // this.$store.dispatch('getPages')
  },
  components: {
    Header,
    Footer,
    SocIcons
  }
}
</script>
