import {fbAuth} from '@/firebase/config'
import {createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail} from 'firebase/auth'
import router from '@/router/router'
import Toast from '@/utils/toast'
import TBot from '@/utils/nTBot'

const logger = false

export default {
  state: {
    user: {loader: true, loaderBtn: false, uid: null, user: null}
  },
  actions: {
    // Регистрация пользователя по почте и паролю
    createUser(ctx, {email, password}) {
      ctx.state.user.loaderBtn = true
      createUserWithEmailAndPassword(fbAuth, email, password)
        .then(userCredential => {
          ctx.state.user.loaderBtn = false
          TBot(`новый пользователь ${userCredential.user.email}`)
          if (logger) console.log('Успешная регистрация пользователя', userCredential)
          Toast.mes('Добро пожаловать!')
        })
        .catch(error => {
          ctx.state.user.loaderBtn = false
          if (logger) console.log('Ошибка регистрации', error)
          Toast.err(error.code ? error.code : error.message)
        })
    },
    signIn(ctx, {email, password}) {
      ctx.state.user.loaderBtn = true
      signInWithEmailAndPassword(fbAuth, email, password)
        .then(userCredential => {
          ctx.state.user.loaderBtn = false
          if (logger) console.log('Успешная авторизация', userCredential)
          Toast.mes('Добро пожаловать!')
        })
        .catch(error => {
          ctx.state.user.loaderBtn = false
          if (logger) console.log('Ошибка авторизации', error)
          Toast.err(error.code ? error.code : error.message)
        })
    },
    forgotPasword(ctx, email) {
      ctx.state.user.loaderBtn = true
      sendPasswordResetEmail(fbAuth, email)
        .then(() => {
          ctx.state.user.loaderBtn = false
          if (logger) console.log('Письмо для сброса пароля отправлено!')
          Toast.mes('Письмо для сброса пароля отправлено!')
          router.push('/login?email=' + email)
        })
        .catch(error => {
          ctx.state.user.loaderBtn = false
          if (logger) console.log('Письмо для сброса пароля отправить не удалось!', error)
          Toast.err(error.code ? error.code : error.message)
        })
    },
    signOut() {
      signOut(fbAuth)
        .then(() => {
          if (logger) console.log('Успешная деавторизация')
          Toast.err('Вы вышли из аккаунта')
        })
        .catch(error => {
          if (logger) console.log('Выход из аккаунта не удался', error)
          Toast.err(error.code ? error.code : error.message)
        })
    }
  },
  mutations: {
    setUser(state, payload) {
      state.user.user = payload
      state.user.uid = payload ? payload.uid : null
      state.user.loader = false
      if (logger) console.log('State пользователя и ID пользователя установлены', state.user.user, state.user.uid)
    },
    setUserId(state, payload) {
      state.user.uid = payload
      state.user.loader = true
      if (logger) console.log('State ID пользователя установлен', state.user.uid)
    }
  },
  getters: {
    user(state) {
      return state.user.user
    },
    uid(state) {
      return state.user.uid
    },
    loader(state) {
      return state.user.loader
    },
    loaderBtn(state) {
      return state.user.loaderBtn
    }
  }
}
