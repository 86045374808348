<template>
  <Loader v-if="loader" />
  <component v-else :is="layout">
    <router-view />
  </component>
</template>

<script>
import 'materialize-css/dist/js/materialize.min.js'
import EmptyLayout from '@/layouts/EmptyLayout'
import MainLayout from '@/layouts/MainLayout'

export default {
  data() {
    return {
      loader: true
    }
  },
  mounted() {
    if (localStorage.getItem('theme') === 'true') {
      document.querySelector('body').classList.add('dark')
    }
  },
  computed: {
    layout() {
      // console.log('app.vue', this.$route.meta.auth, this.$store.getters.user)
      // console.log('app.vue user is null', this.$store.getters.user === null)
      if (this.logger) console.log((this.$route.meta.layout || 'empty') + '-layout')
      let layout = this.$route.meta.layout || 'empty'
      // Если на странице требуется авторизация, а данных о пользователе нет, то бежим на страницу авториции
      if (this.$route.meta.auth && !this.$store.getters.user) {
        this.$router.push('/login')
        layout = 'empty'
      }
      return layout + '-layout'
    }
  },
  watch: {
    '$store.getters.loader'(val) {
      this.loader = val
    }
  },
  components: {EmptyLayout, MainLayout}
}
</script>

<style lang="scss">
@import '~materialize-css/dist/css/materialize.min.css';
@import 'assets/style.css';
@import 'assets/admin.css';
@import 'assets/mobile.css';
</style>
