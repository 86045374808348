<template>
  <router-view />
</template>

<script>
export default {}
</script>

<style>
@import url('@/assets/empty-layout.css');
</style>
